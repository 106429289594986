import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs.jsx";
import LocationModal from "../components/modals/LocationModal.jsx";
import RedirectModal from "./modals/RedirectModal";
import {
  releaseTemporarySlot,
  clearSession,
  setModalContents,
  setDgbId,
  setDgbVehicleId,
} from "../store/scheduler";

import LogoTop from "../assets/d-logo-half-l.png";
import LogoBottom from "../assets/d-logo-half-t.png";
import MobileLogo from "../assets/logo.png";
import TagManager from "react-gtm-module";

import "./Header.css";
import SignInSignOutButton from "./SignInOutButtons/SignInSignOutButton";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

const PhoneIcon = () => {
  return (
    <svg viewBox="0 0 36 36" className="ft-icon-location">
      <path d="M27.341 23.773c-0.907-1.055-2.496-1.171-3.55-0.263c-0.094 0.081-0.182 0.169-0.263 0.263c-0.865 0.9-1.762 1.8-2.662 2.696c-0.175 0.218-0.488 0.268-0.721 0.109c-0.576-0.288-1.188-0.576-1.726-0.9C15.882 24.034 13.636 21.981 11.765 19.601c-0.981-1.15-1.793-2.432-2.412-3.815c-0.126-0.232-0.063-0.52 0.144-0.682c0.9-0.9 1.762-1.762 2.626-2.662c1.072-0.913 1.203-2.524 0.288-3.596c-0.088-0.102-0.182-0.2-0.288-0.288c-0.721-0.721-1.403-1.403-2.084-2.084c-0.721-0.721-1.438-1.438-2.159-2.159c-0.907-1.055-2.496-1.171-3.55-0.263c-0.094 0.081-0.182 0.169-0.263 0.263c-0.9 0.9-1.762 1.8-2.662 2.662C0.59 7.737 0.102 8.781 0.038 9.892C-0.042 11.727 0.278 13.555 0.974 15.252c1.35 3.497 3.297 6.736 5.755 9.568c3.237 3.878 7.259 7.031 11.798 9.246c2.032 1.069 4.257 1.715 6.546 1.906c1.543 0.169 3.069-0.422 4.099-1.582c0.756-0.865 1.656-1.618 2.447-2.447c1.072-0.913 1.203-2.524 0.288-3.596c-0.088-0.102-0.182-0.2-0.288-0.288L27.341 23.773zM25.904 17.839l2.769-0.468c-0.9-5.308-5.132-9.424-10.465-10.18l-0.397 2.769C21.942 10.536 25.222 13.727 25.904 17.839L25.904 17.839zM30.256 5.828C27.103 2.657 22.997 0.608 18.567 0l-0.397 2.769c7.649 1.065 13.73 6.956 15.035 14.567l2.769-0.468C35.25 12.686 33.255 8.834 30.256 5.828z"></path>
    </svg>
  );
};

const LocationIcon = () => {
  return (
    <svg viewBox="0 0 36 36" className="ft-icon-location">
      <path d="M30.528 31.68c0 2.844-6.3 4.32-12.528 4.32s-12.528-1.476-12.528-4.32c0-2.34 4.032-3.42 6.444-3.888l0.288 1.584c-3.888 0.72-5.148 1.872-5.148 2.304 0 0.972 4.14 2.736 10.944 2.736s10.944-1.764 10.944-2.736c0-0.468-1.08-1.548-5.184-2.304l0.288-1.584c4.284 0.792 6.48 2.088 6.48 3.888zM18 0c-5.034 0.02-9.108 4.106-9.108 9.144 0 0 0 0 0 0v0c0 7.92 9.108 22.284 9.108 22.284s9.072-14.508 9.072-22.284c0 0 0 0 0 0 0-5.024-4.053-9.102-9.069-9.144l-0.003 0zM18 12.204c-0.01 0-0.024 0-0.036 0-1.69 0-3.06-1.37-3.06-3.06s1.37-3.06 3.06-3.06c1.69 0 3.06 1.37 3.06 3.06v0c0 0 0 0 0 0 0 1.677-1.35 3.04-3.022 3.06l-0.002 0z"></path>
    </svg>
  );
};

const CalendarIcon = () => {
  return (
    <svg viewBox="0 0 36 36" className="ft-icon-location">
      <path d="M31.68 2.88h-4.32v-1.44c0-0.795-0.645-1.44-1.44-1.44s-1.44 0.645-1.44 1.44v0v1.44h-12.96v-1.44c0-0.795-0.645-1.44-1.44-1.44s-1.44 0.645-1.44 1.44v0v1.44h-4.32c-2.386 0-4.32 1.934-4.32 4.32v0v24.48c0 2.386 1.934 4.32 4.32 4.32v0h27.36c2.386 0 4.32-1.934 4.32-4.32v0v-24.48c0-2.386-1.934-4.32-4.32-4.32v0zM31.68 33.12h-27.36c-0.795 0-1.44-0.645-1.44-1.44v0v-18.72h30.24v18.72c0 0.795-0.645 1.44-1.44 1.44v0zM2.88 10.08v-2.88c0-0.795 0.645-1.44 1.44-1.44v0h4.32v1.44c0 0.795 0.645 1.44 1.44 1.44s1.44-0.645 1.44-1.44v0v-1.44h12.96v1.44c0 0.795 0.645 1.44 1.44 1.44s1.44-0.645 1.44-1.44v0v-1.44h4.32c0.795 0 1.44 0.645 1.44 1.44v0v2.88h-30.24z"></path>
    </svg>
  );
};

const HomeIcon = () => {
  return (
    <svg viewBox="0 0 36 36" className="header-home-icon">
      <path d="M36 21.338l-18-13.971l-18 13.971v-5.695l18-13.971l18 13.971zM31.5 20.828v13.5h-9v-9h-9v9h-9v-13.5l13.5-10.125z"></path>
    </svg>
  );
};

export default function Header() {
  // Redux
  const franchise = useSelector((state) => state.scheduler.franchise);
  const [menuOpen, setMenuOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //   }
  // }, []);
  //
  // const alertUser = (e) => {
  //   e.preventDefault();
  //   e.returnValue = "";
  // }

  // Open our hamburger menu on mobile
  const handleMenuClicked = () => {
    setMenuOpen(!menuOpen);
  };

  const baseUrl = "https://www.cbac.com/";
  const franchiseUrl = `https://www.cbac.com/${franchise.urlSlug}`;
  const baseServicesUrl = "https://www.cbac.com/our-services";
  const baseAboutUrl = "https://www.cbac.com/about-us";

  const env = process.env.REACT_APP_ENV;

  const [searchParams, setSearchParams] = useSearchParams();
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const activeAccount = instance.getActiveAccount();

  useEffect(async () => {
    if (searchParams.has("dgb")) {
      const silentTokenRequest = {
        scopes: ["openid", "profile"],
        loginHint: `+${searchParams.get("dgb").trim()}`,
      };

      console.log(silentTokenRequest);
      if (inProgress === InteractionStatus.None && !isAuthenticated) {
        await instance
          .loginRedirect(loginRequest)
          .then((response) => dispatch(setDgbId(response.idTokenClaims.dgbId)))
          .catch((error) => console.log(error));
      }
    }
    if (searchParams.has("vehicleId")) {
      const vehicleId = searchParams.get("vehicleId");
      sessionStorage.setItem("dgb.vehicleId", vehicleId);
      dispatch(setDgbVehicleId(vehicleId));
    }

    if (isAuthenticated) {
      searchParams.delete("dgb");
      setSearchParams(searchParams);
    }
  }, [instance, inProgress]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setDgbId(activeAccount.idTokenClaims.dgbId));
    }
  }, [isAuthenticated]);

  const handleGAPhoneEvent = () => {
    const dataLayer = {
      event: "event_phone_clicked",
      franchiseNumber: franchise.phone,
    };

    TagManager.dataLayer({
      dataLayer,
    });
  };

  // Change user's location when clicked within modal
  const changeLocation = (e) => {
    e.preventDefault();

    // Wipe our session data
    dispatch(releaseTemporarySlot());
    dispatch(clearSession());

    navigate("/no-location");
    closeModal(e);
    // window.location.href = `https://www.cbac.com/locations/`;
  };

  const closeModal = (e) => {
    e.preventDefault();
    dispatch(setModalContents(null));
  };

  const handleLocationClick = (e) => {
    e.preventDefault();
    if (env !== "TEST") {
      const modal = (
        <LocationModal
          handleCancel={closeModal}
          handleContinue={changeLocation}
        />
      );
      dispatch(setModalContents(modal));
    }
  };

  const handleRedirectClick = (e, url) => {
    e.preventDefault();
    if (env !== "TEST") {
      const modal = (
        <RedirectModal
          handleCancel={closeModal}
          handleContinue={() => {
            dispatch(releaseTemporarySlot());
            dispatch(clearSession());

            window.location.href = url;
          }}
        />
      );
      dispatch(setModalContents(modal));
    }
  };

  return (
    <header>
      {/* Mobile */}
      <div className="header-mobile">
        <div className="header-m-trigger">
          <button
            onClick={handleMenuClicked}
            className={menuOpen ? "header-m-opened" : ""}
          >
            <span className="header-menu-bar"></span>
            <span className="header-menu-bar"></span>
            <span className="header-menu-bar"></span>
          </button>
        </div>
        <a href="https://www.cbac.com" className="header-m-logo">
          <img
            alt="Christian Brothers Automotive"
            title="Christian Brothers Automotive"
            src={MobileLogo}
          />
        </a>
        <div
          className={`header-mobile-nav ${menuOpen ? "header-m-opened" : ""}`}
        >
          <nav>
            <ul>
              {/* <li>
                <a href={baseUrl + '/schedule-an-appointment'}><CalendarIcon/> Schedule Your Visit</a>
              </li> */}
              <li>
                <a
                  href="/no-location"
                  // href="https://www.cbac.com/locations/"
                  // href="https://www.cbac.com/locations/"
                  onClick={handleLocationClick}
                >
                  <LocationIcon /> Change Location
                </a>
              </li>
              <li>
                <a
                  href="https://www.cbac.com/our-services/"
                  onClick={(e) =>
                    handleRedirectClick(e, `${baseUrl}/our-services/`)
                  }
                >
                  Our Services
                </a>
              </li>
              <li>
                <a
                  href="https://www.cbac.com/about-us/"
                  onClick={(e) =>
                    handleRedirectClick(e, `${baseUrl}/about-us/`)
                  }
                >
                  About Us
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      {/* Desktop */}
      <div className="header-full">
        <div className="header-logo">
          <a
            className="header-logo-container"
            href="https://www.cbac.com"
            onClick={(e) => handleRedirectClick(e, baseUrl)}
          >
            <div className="header-logo-holder">
              <div className="header-logo-wrapper">
                <img
                  alt="Christian Brothers Automotive"
                  title="Christian Brothers Automotive"
                  src={LogoTop}
                />
                <img
                  alt="Christian Brothers Automotive"
                  title="Christian Brothers Automotive"
                  src={LogoBottom}
                />
              </div>
            </div>
          </a>
        </div>
        <div className="header-center">
          <div className="header-top">
            <p className="header-phone">
              {franchise.phone && (
                <a
                  onClick={handleGAPhoneEvent}
                  href={env !== "TEST" ? `tel:${franchise.phone}` : null}
                >
                  <PhoneIcon />
                  {franchise.phone}
                </a>
              )}
            </p>
            <ul>
              <li>
                <a
                  href="https://www.cbac.com/about-us/careers/"
                  onClick={(e) =>
                    handleRedirectClick(e, `${baseAboutUrl}/careers/`)
                  }
                >
                  Careers
                </a>
              </li>
              <li>
                <a
                  href={`${franchiseUrl}/contact-us/`}
                  onClick={(e) =>
                    handleRedirectClick(e, `${franchiseUrl}/contact-us/`)
                  }
                >
                  Contact Us
                </a>
              </li>
              <li className="header-location-name">
                <a
                  href={franchiseUrl}
                  onClick={(e) => handleRedirectClick(e, franchiseUrl)}
                >
                  {franchise.franchiseName}
                </a>
              </li>
            </ul>
            <a
              // href="https://www.cbac.com/locations/"
              href="/no-location"
              onClick={handleLocationClick}
              className="header-location"
            >
              <LocationIcon /> Change Location
            </a>
            <SignInSignOutButton />
          </div>
          <div className="header-nav-main">
            <nav>
              <ul>
                <li>
                  <a
                    href={franchiseUrl}
                    onClick={(e) => handleRedirectClick(e, `${franchiseUrl}`)}
                  >
                    <HomeIcon />
                  </a>
                </li>
                <li className="header-services-link">
                  <a
                    href="https://www.cbac.com/our-services/"
                    onClick={(e) =>
                      handleRedirectClick(e, `${baseUrl}/our-services/`)
                    }
                    className="header-dd-link"
                  >
                    Our Services
                  </a>
                  {env !== "TEST" ? (
                    <div className="header-dd-services header-dd">
                      <div className="header-dd-nav">
                        <ul className="header-dd-link-container">
                          <a
                            href={baseServicesUrl + "/ac-services"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/ac-services`
                              )
                            }
                          >
                            AC Services
                          </a>
                          <a
                            href={baseServicesUrl + "/air-filtration"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/air-filtration`
                              )
                            }
                          >
                            Air Filtration
                          </a>
                          <a
                            href={baseServicesUrl + "/alignment"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/alignment`
                              )
                            }
                          >
                            Alignment
                          </a>

                          <a
                            href={baseServicesUrl + "/auto-repair-estimates"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/auto-repair-estimates`
                              )
                            }
                          >
                            Auto Repair Estimates
                          </a>
                          <a
                            href={baseServicesUrl + "/batteries"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/batteries`
                              )
                            }
                          >
                            Batteries
                          </a>
                          <a
                            href={baseServicesUrl + "/brakes"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/brakes`
                              )
                            }
                          >
                            Brakes
                          </a>

                          <a
                            href={baseServicesUrl + "/check-engine-light"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/check-engine-light`
                              )
                            }
                          >
                            Check Engine Light
                          </a>
                          <a
                            href={baseServicesUrl + "/computer-diagnostic"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/computer-diagnostic`
                              )
                            }
                          >
                            Computer Diagnostic
                          </a>
                          <a
                            href={baseServicesUrl + "/cooling-system"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/cooling-system`
                              )
                            }
                          >
                            Cooling System
                          </a>

                          <a
                            href={baseServicesUrl + "/courtesy-inspections"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/courtesy-inspections`
                              )
                            }
                          >
                            Courtesy Inspections
                          </a>
                          <a
                            href={baseServicesUrl + "/drivetrain-suspension"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/drivetrain-suspension`
                              )
                            }
                          >
                            Drivetrain & Suspension
                          </a>
                          <a
                            href={baseServicesUrl + "/electrical-system"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/electrical-system`
                              )
                            }
                          >
                            Electrical System
                          </a>

                          <a
                            href={baseServicesUrl + "/engine-services"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/engine-services`
                              )
                            }
                          >
                            Engine Services
                          </a>
                          <a
                            href={baseServicesUrl + "/exhaust-system"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/exhaust-system`
                              )
                            }
                          >
                            Exhaust System
                          </a>
                          <a
                            href={
                              baseServicesUrl + "/extended-warranty-service"
                            }
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/extended-warranty-service`
                              )
                            }
                          >
                            Extended Warranty Service
                          </a>

                          <a
                            href={baseServicesUrl + "/fleet-service"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/fleet-service`
                              )
                            }
                          >
                            Fleet Service
                          </a>
                          <a
                            href={baseServicesUrl + "/fuel-system"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/fuel-system`
                              )
                            }
                          >
                            Fuel System
                          </a>
                          <a
                            href={baseServicesUrl + "/inspection-emissions"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/inspection-emissions`
                              )
                            }
                          >
                            Inspection & Emissions
                          </a>

                          <a
                            href={baseServicesUrl + "/oil-filter-change"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/oil-filter-change`
                              )
                            }
                          >
                            Oil & Filter Change
                          </a>
                          <a
                            href={baseServicesUrl + "/power-steering"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/power-steering`
                              )
                            }
                          >
                            Power Steering
                          </a>
                          <a
                            href={baseServicesUrl + "/pre-purchase-inspections"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/pre-purchase-inspections`
                              )
                            }
                          >
                            Pre Purchase Inspections
                          </a>

                          <a
                            href={baseServicesUrl + "/scheduled-maintenance"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/scheduled-maintenance`
                              )
                            }
                          >
                            Scheduled Maintenance
                          </a>
                          <a
                            href={baseServicesUrl + "/tire-rotation"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/tire-rotation`
                              )
                            }
                          >
                            Tire Rotation
                          </a>
                          <a
                            href={baseServicesUrl + "/transmission-services"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/transmission-services`
                              )
                            }
                          >
                            Transmission Services
                          </a>

                          <a
                            href={baseServicesUrl + "/tune-ups"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseServicesUrl}/tune-ups`
                              )
                            }
                          >
                            Tune Ups
                          </a>
                        </ul>
                      </div>
                    </div>
                  ) : null}
                </li>
                <li className="header-about-link">
                  <a
                    className="header-dd-link"
                    href="https://www.cbac.com/about-us/"
                    onClick={(e) => handleRedirectClick(e, baseAboutUrl)}
                  >
                    About Us
                  </a>
                  {env !== "TEST" ? (
                    <div className="header-dd-about header-dd">
                      <div className="header-dd-nav">
                        <ul className="header-dd-link-container">
                          <a
                            href={franchiseUrl + "/about-us/about-our-store"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${franchiseUrl}/about-us/about-our-store`
                              )
                            }
                          >
                            About Our Store
                          </a>
                          <a
                            href={baseAboutUrl + "/nice-difference-warranty"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseAboutUrl}/nice-difference-warranty`
                              )
                            }
                          >
                            Nice Difference Warranty
                          </a>
                          <a
                            href={baseAboutUrl + "/car-tips"}
                            onClick={(e) =>
                              handleRedirectClick(e, `${baseAboutUrl}/car-tips`)
                            }
                          >
                            Car Tips
                          </a>

                          <a
                            href={baseAboutUrl + "/why-choose-us"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseAboutUrl}/why-choose-us`
                              )
                            }
                          >
                            Why Choose Us
                          </a>
                          <a
                            href={franchiseUrl + "/media-center/blog"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${franchiseUrl}/media-center/blog`
                              )
                            }
                          >
                            Blog
                          </a>
                          <a
                            href={baseAboutUrl + "/faqs"}
                            onClick={(e) =>
                              handleRedirectClick(e, `${baseAboutUrl}/faqs`)
                            }
                          >
                            Faqs
                          </a>

                          <a
                            href={franchiseUrl + "/about-us/testimonials"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${franchiseUrl}/about-us/testimonials`
                              )
                            }
                          >
                            Testimonials
                          </a>
                          <a
                            href={baseAboutUrl + "/careers"}
                            onClick={(e) =>
                              handleRedirectClick(e, `${baseAboutUrl}/careers`)
                            }
                          >
                            Careers
                          </a>
                          <a
                            href={baseAboutUrl + "/free-shuttle-service"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseAboutUrl}/free-shuttle-service`
                              )
                            }
                          >
                            Free Shuttle Service
                          </a>

                          <a
                            href={baseAboutUrl + "/company-history"}
                            onClick={(e) =>
                              handleRedirectClick(
                                e,
                                `${baseAboutUrl}/company-history`
                              )
                            }
                          >
                            Company History
                          </a>
                        </ul>
                      </div>
                    </div>
                  ) : null}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {franchise.schedulerLive && location.pathname !== "/no-location" ? (
        <Breadcrumbs />
      ) : null}
    </header>
  );
}
