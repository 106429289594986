import "./NoLocationScreen.css";
import { StoreLocator } from "../components/StoreLocator/StoreLocator";

export default function NoLocationScreen() {
  return (
    <div className="no-location">
      <h1 className="heading">
        Before you get started, you need to find a local shop.
      </h1>
      <div className={"store-locator"}>
        <StoreLocator />
      </div>
    </div>
  );
}
